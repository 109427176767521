import { z } from "./openapizod";
import * as Shared from "./shared.api.schema";

export const vehicleExtraGroupName = z.enum(["luggages", "bikes", "child_seats", "pets"]);
export type VehicleExtraGroupName = z.infer<typeof vehicleExtraGroupName>;

export const vehicleExtraName = z.enum([
  "large_luggage",
  "small_luggage",
  "bike",
  "bike_bag",
  "wheelchair",
  "ski",
  "dog",
  "other_pets",
  "baby_seat",
  "child_seat",
  "booster",
]);
export type VehicleExtraName = z.infer<typeof vehicleExtraName>;

const vehicleExtraSurchargeType = z.enum(["single", "multi", "custom"]);
export type VehicleExtraSurchargeType = z.infer<typeof vehicleExtraSurchargeType>;

const surchargeOperation = z.object({
  operator: z.enum(["+", "*"]),
  value: z.number(),
});

export type SurchargeOperation = z.infer<typeof surchargeOperation>;

const vehicleExtraSingle = z.object({
  type: z.literal("single"),
  name: vehicleExtraName,
  surcharge: z.object({ type: z.literal("single"), operation: surchargeOperation }),
  volume: z.number().min(0),
});
export type VehicleExtraSingle = z.infer<typeof vehicleExtraSingle>;

const vehicleExtraMulti = z.object({
  type: z.literal("multi"),
  name: vehicleExtraName,
  maxCount: z.number().int(),
  surcharge: z.union([
    z.object({
      type: z.literal("single"),
      operation: surchargeOperation,
    }),
    z.object({
      type: z.literal("multi"),
      operation: surchargeOperation,
    }),
    z.object({
      type: z.literal("custom"),
      data: z.array(z.object({ count: z.number().int().min(1), operation: surchargeOperation })),
    }),
  ]),
  volume: z.number().min(0),
});
export type VehicleExtraMulti = z.infer<typeof vehicleExtraMulti>;

const vehicleExtraGroup = z.object({
  type: z.literal("group"),
  name: vehicleExtraGroupName,
  extras: z.array(z.union([vehicleExtraSingle, vehicleExtraMulti])),
  maxVolume: z.number().min(0).optional(),
});
export type VehicleExtraGroup = z.infer<typeof vehicleExtraGroup>;
export const vehicleExtra = vehicleExtraSingle.or(vehicleExtraMulti);
export type VehicleExtra = z.infer<typeof vehicleExtra>;
export const vehicleExtraOrGroup = vehicleExtra.or(vehicleExtraGroup);
export type VehicleExtraOrGroup = z.infer<typeof vehicleExtraOrGroup>;

export const responseBodyItem = z.object({
  id: z.string(),
  imageUrls: z.array(z.string()),
  name_i18n: z.record(Shared.locale, z.string()),
  maxPassengerCount: z.number().int(),
  minPassengerCount: z.number().int(),
  extras: z.array(vehicleExtraOrGroup),
  price: z.number(),
  exceptionReasons: z.array(Shared.i18nValue),
  vatRate: z.number().min(0),
  netPrice: z.number().nonnegative(),
});
export type ResponseBodyItem = z.infer<typeof responseBodyItem>;

export const responseBody = z.array(responseBodyItem).min(1);
export type ResponseBody = z.infer<typeof responseBody>;

export namespace Post {
  export const requestBody = z.object({
    route_id: z.string(),
    passengerCount: z.number().int().nonnegative(),
    when: z.object({ date: Shared.date, time: Shared.time }).or(z.literal("now")),
    discount: z.number().min(-1).optional(),
  });
  export type RequestBody = z.infer<typeof requestBody>;

  export const requestParams = z.object({
    onlinebooking_config_id: z.string(),
  });

  export type RequestParams = z.infer<typeof requestParams>;
}

export const taxiroutes_cartype = z.enum([
  "minibus",
  "vw",
  "mercedes-vito",
  "mercedes-v",
  "mercedes-gls",
  "mercedes-e",
  "mercedes-s",
  "limousine",
  "mercedes",
  "bus",
]);

export type TaxiroutesCartype = z.infer<typeof taxiroutes_cartype>;
